<template>
  <v-footer :color="getStoreUser == '' ? '#b61c34' : getStoreUser.color">
    <v-container fluid class="px-1">
      <v-row>
        <v-col class="text-center">
          <v-btn
            v-for="socialmedia in socialmedias"
            :key="socialmedia.id"
            :href="socialmedia.link"
            target="_blank"
            class="mx-4"
            icon
            size="small"
            elevation="3"
          >
            <v-icon size="large" :class="socialmedia.icon" class="" />
          </v-btn>
        </v-col>
      </v-row>
      <v-divider class="my-5"></v-divider>
      <v-row class="text-center" align="center">
        <!-- Columna para correo electrónico de contacto -->
        <v-col cols="12" md="4">
          <v-btn
            href="mailto: pae@gobernova.com.mx"
            :icon="mdi.email"
            variant="text"
            target="_blank"
            density="comfortable"
          ></v-btn>
          <span>pae@gobernova.com.mx</span>
        </v-col>
        <!-- Columna para números telefónicos -->
        <v-col cols="12" md="4">
          <v-btn
            href="tel:722-544-7945"
            :icon="mdi.building"
            variant="text"
            target="_blank"
            density="comfortable"
          ></v-btn>
          <span>722 544 7945</span>
          <br />
          <v-btn
            href="tel:722-795-7040"
            :icon="mdi.phone"
            variant="text"
            target="_blank"
            density="comfortable"
          ></v-btn>
          <span>+52 722 795 7040</span>
          <br />
          <v-btn
            href="https://api.whatsapp.com/send?phone=7227957040"
            :icon="mdi.whatsapp"
            variant="text"
            target="_blank"
            density="comfortable"
          ></v-btn>
          <span>+52 722 795 7040</span>
        </v-col>
        <!-- Columna para ubicación -->
        <v-col cols="12" md="4">
          <v-btn
            href="https://maps.app.goo.gl/UuwuFWPRSp4R4whV7"
            :icon="mdi.map"
            variant="text"
            target="_blank"
            density="comfortable"
          ></v-btn
          ><br />
          <span>
            Ex Hacienda Barbabosa <br />
            100-A Col. San Miguel <br />
            Zinacantepec C.P. 51357
          </span>
        </v-col>
      </v-row>
      <!-- Horarios -->
      <v-row class="mt-4 text-center">
        <v-col cols="12">
          <v-icon :icon="mdi.clock" class="mr-2" />
          <span>Lunes a Viernes: 9am - 6pm</span>
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>

<script>
export default {
  name: "AppFooter",
  data: () => ({
    socialmedias: [
      {
        id: 1,
        icon: "fa-brands fa-facebook",
        link: "https://www.facebook.com/gobernovaconsultoria",
      },
      {
        id: 2,
        icon: "fa-brands fa-youtube",
        link: "https://www.youtube.com/@gobernova2416",
      },
      {
        id: 3,
        icon: "fa-brands fa-square-x-twitter",
        link: "https://twitter.com/gobernova",
      },
      {
        id: 4,
        icon: "fa-brands fa-linkedin",
        link: "https://www.linkedin.com/company/gobernova/?viewAsMember=true",
      },
      {
        id: 5,
        icon: "fa-brands fa-square-instagram",
        link: "https://www.instagram.com/gobernova_/",
      },
    ],
    mdi: {
      user: "mdi mdi-account-outline",
      email: "mdi mdi-email",
      building: "mdi mdi-office-building",
      phone: "mdi mdi-phone",
      whatsapp: "mdi mdi-whatsapp",
      map: "mdi mdi-map-marker",
      clock: "mdi mdi-clock-time-four",
    },
  }),
  computed: {
    getStoreUser() {
      const userState = this.$store.state.user;
      if (userState != "") {
        const user = JSON.parse(userState);
        return user;
      } else {
        return "";
      }
    },
  },
};
</script>
